import keyMirror from './utils/keyMirror';

export const PROCTORING_API_URL =
  process.env.REACT_APP_PROCTORING_API_URL ||
  'https://proctoringapp-backend-dev.sciodev.cz';

export const SECTIONS = keyMirror({
  INTRO: null,
  EXAM: null,
});

export const EXAM = {
  CAPTURED_CHUNK_CAM: 'videoChunkCaptured',
  CAPTURED_CHUNK_SCREEN: 'screenChunkCaptured',
  CAPTURED_SNAP_CAM: 'videoSnapshotsCaptured',
  CAPTURED_SNAP_SCREEN: 'screenSnapshotsCaptured',
  EXAM_ID: 'examId',
  EXTERNAL_EXAM_ID: 'externalExamId',
  IDB_STORE_NAME: 'proctoring',
  LAST_CHUNK_RECEIVED_AT: 'lastChunkReceivedAt',
  MIC_ID: 'microphoneDeviceId',
  WEBCAM_ID: 'webcamDeviceId',
};

export const EXAM_WAS_FINISHED = 'examWasFinished';

export const CHECK = {
  DETECT_FACE: 'detect_face',
  EXAM: 'during_exam',
  ID: 'identification',
  PROCESS_APP: 'process',
  ROOM: 'room',
  SCREEN: 'screen',
  VIDEO: 'video',
};

export const MIN_CONNECTION_SPEED = 1;

export const EXAM_WAS_OPENED = 'examWasOpened';

export const MONITORING_APP_URL = 'proctoring://';

export const LOCALE = 'cz';

export const MAX_BLOB_SIZE = 4000000;

// All Supported Constraints: navigator.mediaDevices.getSupportedConstraints();
export const CAM_VIDEO_CONFIG = {
  width: { max: 960 },
  height: { max: 720 },
  frameRate: { max: 20 },
};

export const SCREEN_STREAM_CONFIG = {
  video: {
    width: { max: 800 },
    height: { max: 600 },
    frameRate: { max: 5 },
    displaySurface: 'monitor',
  },
};

export const CAM_RECORDER_CONFIG = {
  type: 'video',
  mimeType: 'video/x-matroska;codecs=avc1',
  bitsPerSecond: 600000,
};

export const SCREEN_RECORDER_CONFIG = {
  type: 'video',
  mimeType: 'video/x-matroska;codecs=avc1',
  bitsPerSecond: 300000,
};

export const CAM_SNAPSHOT_RESOLUTION = { width: 480, height: 320 };
export const SCREEN_SNAPSHOT_RESOLUTION = { width: 224, height: 224 };
export const SNAPSHOT_QUALITY = 0.8; // quality at 80%
export const SNAPSHOT_TYPE = 'image/jpeg';

export const CHUNK_LENGTH = 5000;

export const FAILED_REQUEST_INTERVALS = [
  1000, // 1 sec.
  5 * 1000, // 5 secs.
  30 * 1000, // 30 secs.
  60 * 1000, // 60 secs.
  5 * 60 * 1000, // 5 mins.
  10 * 60 * 1000, // 10 mins.
];
export const MAX_FAILED_REQUEST_INTERVAL =
  FAILED_REQUEST_INTERVALS[FAILED_REQUEST_INTERVALS.length - 1];
export const TRY_RECONNECT_INTERVAL = 1000 * 5;
export const CREATE_EXAM_RECONNECT_INTERVAL = 1000 * 60;

export const NO_INTERNET_NOTIF_DELAY = 10000;

export const VIDEO_SEGMENT_LENGTH = 60000;

export const NOTIF_KEY = keyMirror({
  CAM: null,
  EXAM_PREPARING: null,
  NO_INTERNET: null,
  SCREEN: null,
  STORAGE: null,
  CHECKS_TIMER: null,
});

export const DEFAULT_CAM_ASPECT_RATIO = 1.3333333333333333;
