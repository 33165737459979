import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isEmpty } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Webcam from 'react-webcam';
import { CAM_VIDEO_CONFIG, EXAM } from '../../consts';
import { camDisabledIcon } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import LSM from '../../utils/localStorageManager';
import logger from '../../../utils/logging/DefaultLogger';
import { checkVideoStream } from '../../utils/stream';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

let checkCamStream = null;

const Camera = ({ isActive }) => {
  const intl = useIntl();
  const {
    allowContinue,
    externalExamId,
    examId,
    setAllowContinue,
    setWebcamDeviceId,
    webcamDeviceId,
  } = useContext(Context);

  const [error, setError] = useState(null);
  const [webcamPermissionGranted, setWebcamPermissionGranted] = useState(false);
  const [videoDevices, setVideoDevices] = useState([]);
  const [isInitDelayDone, setIsInitDelayDone] = useState(false);

  useEffect(() => {
    if (isActive) setTimeout(() => setIsInitDelayDone(true), 4000);
  }, [isActive]);

  const didCamPass =
    webcamPermissionGranted && webcamDeviceId && videoDevices.length > 0;

  useEffect(() => {
    if (!isActive) return;

    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        navigator.permissions
          .query({ name: 'camera' })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'granted') {
              setWebcamPermissionGranted(true);
            }
            permissionStatus.onchange = function callback() {
              setWebcamPermissionGranted(
                this.state === 'granted' ? true : false
              );
            };
          });
      })
      .catch(() => {
        setWebcamPermissionGranted(false);
        setError(
          <>
            <FormattedMessage {...messages.allowCameraInOs} />{' '}
            <FormattedMessage {...messages.thenSelectCam} />
          </>
        );
      });
  }, [isActive]);

  useEffect(() => {
    if (!isActive || !webcamPermissionGranted) return;

    const checkCam = async () => {
      let videoDevicesIds;

      // 1. Get all cam devices
      await navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        const videoDevices = mediaDevices.filter(
          ({ kind }) => kind === 'videoinput'
        );
        videoDevicesIds = videoDevices.map((device) => device.deviceId);

        if (!isEmpty(videoDevices)) {
          // 1.1 Set them to state so they can be listed in the select element
          setVideoDevices(videoDevices);

          // 1.2 If webcamDeviceId doesn't exist yet, set it to LS and context state
          if (!webcamDeviceId) {
            const defaultDeviceId = videoDevicesIds?.[0] || undefined;

            setWebcamDeviceId(defaultDeviceId);
            LSM.setExamProperty(
              externalExamId,
              EXAM.WEBCAM_ID,
              defaultDeviceId
            );
          }
        }
      });

      // 2. Check if default camera with webcamDeviceId is still connected
      if (videoDevicesIds.includes(webcamDeviceId) && webcamPermissionGranted) {
        // 2.1 Check permissions, set setError
        checkCamStream = await checkVideoStream({
          // Prevents OverconstrainedError if the deviceId is ''
          webcamDeviceId: webcamDeviceId || undefined,
          videoConf: CAM_VIDEO_CONFIG,
          errorHandler: (error) => {
            logger.warn(`Error while checking webcam videoStream: ${error}`, {
              examId,
              externalExamId,
              externalExamIdSentry: localStorage.getItem(
                'externalExamId4Sentry'
              ),
              exception: error,
            });

            setError(
              <FormattedMessage {...messages.turnOnWebCamAndRestartBrowser} />
            );
          },
        });

        // 2.2 Set err notif when a user plugs the camera off manually
        checkCamStream.getVideoTracks()[0].onended = () => {
          setError(
            <FormattedMessage {...messages.turnOnWebCamAndRestartBrowser} />
          );
          setWebcamPermissionGranted(false);
        };
      } else {
        // 3. if default camera with webcamDeviceId is not connected anymore,
        // set new webcamDeviceId - this will cause to run this useEffect again
        setWebcamDeviceId(videoDevicesIds[0]);
        LSM.setExamProperty(externalExamId, EXAM.WEBCAM_ID, videoDevicesIds[0]);
      }
    };

    checkCam();
  }, [
    externalExamId,
    webcamDeviceId,
    setWebcamDeviceId,
    webcamPermissionGranted,
    examId,
    setError,
    isActive,
  ]);

  useEffect(() => {
    if (didCamPass && !allowContinue && isActive) setAllowContinue(true);
  }, [allowContinue, didCamPass, isActive, setAllowContinue]);

  return (
    <div className="introCheck">
      <div className="beside">
        <div>
          <h2 className="header2Semibold">
            <FormattedMessage {...messages.allowCamAccess} />
          </h2>

          <p className="textMdNormal">
            <FormattedMessage {...messages.makeSureCameraIsConnected} />{' '}
            <FormattedMessage {...messages.thenSelectCam} />
          </p>
        </div>
        <div>
          {/* Playback */}
          {didCamPass ? (
            <div className="video-wrapper">
              <Webcam
                className="cam-select-preview"
                audio={false}
                height={330}
                width={500}
                videoConstraints={
                  webcamDeviceId ? { deviceId: webcamDeviceId } : {}
                }
              />
            </div>
          ) : (
            <div className="cam-disabled-icon">
              {isInitDelayDone && camDisabledIcon}
            </div>
          )}

          {/* Select webcam */}
          <div className="cam-mic-selection-box">
            {webcamPermissionGranted && (
              <FormControl>
                <InputLabel id="label-id-select-cam">
                  <FormattedMessage {...messages.selectCam} />
                </InputLabel>
                <Select
                  IconComponent={({ className }) =>
                    className.includes('iconOpen') ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  }
                  labelId="label-id-select-cam"
                  label={<FormattedMessage {...messages.selectCam} />}
                  value={webcamDeviceId}
                  onChange={(e) => {
                    LSM.setExamProperty(
                      externalExamId,
                      EXAM.WEBCAM_ID,
                      e.target.value
                    );
                    setWebcamDeviceId(e.target.value);
                  }}
                  variant="outlined"
                >
                  {videoDevices.map((device, key) => (
                    <MenuItem
                      key={key}
                      value={device.deviceId}
                      aria-label={`${intl.formatMessage(messages.camera)} ${
                        device.label || intl.formatMessage(messages.unknownCam)
                      }`}
                    >
                      {device.label || (
                        <FormattedMessage {...messages.unknownCam} />
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* Errors */}
            {isInitDelayDone && (error || videoDevices.length === 0) && (
              <div className="error textXsMedium mt25">
                {error || (
                  <>
                    <FormattedMessage {...messages.noCamerasFound} />{' '}
                    <FormattedMessage {...messages.connectAndRestart} />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Camera.propTypes = {
  isActive: PropTypes.bool,
};

export default Camera;
